/*@font-face {
    font-family: "LatoCustom";
    src: url(../.../fonts/Lato/Lato-Light.ttf) format("truetype");    

}*/
body {
  font-family: 'Lato', sans-serif !important;
}

.th_listar_cb {
  width: 30px !important;
  text-align: center;
}

.no-padding {
  padding: 0px;
}

.no-padding-left {
  padding-left: 0px;
}

.no-padding-right {
  padding-right: 0px;
}

.form-horizontal .control-label {
  text-align: left;
}

.text-red {
  color: #A81118;
}

.btn-primary {
  background-color: #A81118;
  border-color: #A81118;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary.active:hover,
.btn-primary.active:focus {
  background-color: #A81118;
  border-color: #A81118;
  opacity: 0.8;
}

@media (max-width: 1199px) {
  .no-padding-right {
    padding-right: 15px;
  }
  .no-padding-left {
    padding-left: 15px;
  }
  .no-padding-left {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.page-heading, .md-skin .page-heading {
  padding: 10px 20px;
}

.select2-container .select2-selection--single {
  height: 32px !important;
  border-radius: 0px !important;
  border: 1px solid #e5e6e7 !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 32px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 32px !important;
}

#form_editar .box-footer {
  margin-top: 15px;
  border-top: 1px #CCC dashed;
  padding-top: 15px;
}

body.modal-open .select2-dropdown {
  z-index: 3000 !important;
}

.modal .select2-container {
  width: 100% !important;
}

.nav.navbar-top-links.navbar-right li a {
  color: #A81118;
}

.md-skin .nav .open > a, .md-skin .nav .open > a:hover, .md-skin .nav .open > a:focus {
  color: white;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
}

.custom-checkbox + label {
  position: relative;
  margin: 5px;
  cursor: pointer;
  padding: 0;
}

.custom-checkbox + label:before {
  content: '';
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #777;
  border-radius: 50%;
}

.custom-checkbox:hover + label:before {
  background: #A81118;
}

.custom-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.custom-checkbox:checked + label:before {
  background: #A81118;
}

.custom-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.custom-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.custom-checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 3px;
  height: 3px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

#tabla_listar tr {
  cursor: pointer;
}

button.btn-danger.dim {
  box-shadow: inset 0 0 0 #A81118, 0 5px 0 0 #A81118, 0 10px 5px #999999;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger, .btn-danger:active:focus, .btn-danger:active:hover, .btn-danger.active:hover, .btn-danger.active:focus {
  background-color: #A81118;
  border-color: #A81118;
  color: #FFFFFF;
  opacity: 0.8;
}

.bg-danger {
  background-color: #A81118;
  border-color: #A81118;
  color: #FFFFFF;
}

.txt-red {
  color: #A81118;
}

button.btn-danger.dim:active {
  box-shadow: inset 0 0 0 #A81118, 0 2px 0 0 #A81118, 0 5px 3px #999999;
  opacity: 0.8;
}

#btn_cancelar, #btn_listar_eliminar {
  background-color: #676a6c;
  border-color: #676a6c;
  color: #FFFFFF;
}

#btn_cancelar:hover, #btn_cancelar:focus, #btn_cancelar:active, #btn_listar_eliminar:hover, #btn_listar_eliminar:focus, #btn_listar_eliminar:active {
  background-color: #A81118;
  border-color: #A81118;
}

.b-r-dashed {
  border-right: 1px dashed #e7eaec;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary.active[disabled], fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
  background-color: #A81118;
  border-color: #A81118;
}

.fa-periscope {
  background-image: url("assets/img/general/periscope.png");
  width: 21px;
  height: 26px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 767px) {
  .md-skin.mini-navbar .nav-second-level {
    background-color: #5f5f5f;
  }
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
  background-color: #A81118;
  border-color: #A81118;
  opacity: 0.8;
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
  color: #676a6c !important;
}

.nav-tabs > li > a {
  color: #A7B1C2 !important;
  font-weight: 600;
  padding: 10px 20px 10px 25px;
}

fieldset {
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  padding: 15px;
}

fieldset legend {
  font-size: 16px;
}

#chart-content {
  position: relative;
}

#chart-preloader {
  position: absolute;
  top: 0;
  left: 0;
  background-color: gray;
  opacity: 0.5;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.chart-preloader {
  position: absolute;
  top: 0;
  left: 0;
  background-color: gray;
  opacity: 0.5;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.c-ibox-content {
  display: flex;
  justify-content: flex-end;
}

.c-item-box {
  margin: 10px;
}

#table-country {
  margin-top: 15px;
}

.option-bloked {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0.4;
  font-weight: bold;
  font-size: 12px;
}

.country_code_allow {
  color: #A81118;
  font-size: 11px;
  cursor: pointer;
}

.lbl-country_code {
  display: flex;
  justify-content: space-between;
}

.notification-unread {
  font-weight: bold;
  background-color: #F4D0D2;
}

#notifications-list {
  width: 350px;
  max-height: 350px;
  overflow: auto;
}

.notification-item > li > a:focus, .dropdown-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
  max-width: 100%;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

.ibox-tools button {
  margin-left: 10px;
}
